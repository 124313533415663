import React from 'react';
import { Button, Col, Form, FormGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

import { createPriceListMultiple } from '../../../redux/actions/priceListActions';
import { getCorrectDate, getCorrectDateEnd } from '../../../utils';

import PriceListItemValidationSchema from './validation';

const PriceListItemFormMultiple = (props) => {
  const { priceListItem, buttonText, onSubmit } = props;

  const token = useSelector((state) => state.user.token);
  const loadingAdd = useSelector((state) => state.admin.priceList.loadings.priceListCreate);
  const loadingEdit = useSelector((state) => state.admin.priceList.loadings.priceListUpdate);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(createPriceListMultiple(token, values));
    onSubmit();
  };

  const initialValues = {
    type: '',
    from: getCorrectDate(priceListItem ? priceListItem.from : new Date()),
    to: priceListItem ? getCorrectDateEnd(priceListItem.to) : undefined,
    price: priceListItem ? priceListItem.price : 0,
    reducedPrice: priceListItem ? priceListItem.reducedPrice : 0,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={PriceListItemValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, setFieldValue, setFieldError, handleSubmit }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Col xs={{ span: 6, offset: 3 }}>
              <Form.Group>
                <Form.Label>Typ *</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={!values.type || values.type === '' ? 'Vyberte typ' : values.type}
                  placeholder="Vyberte typ"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  isInvalid={touched.foodId && errors.foodId}>
                  {Object.values(['all', 'breakfast', 'lunch', 'dinner']).map((type) => {
                    let label;
                    switch (type) {
                      case 'breakfast':
                        label = 'Snídaně';
                        break;
                      case 'lunch':
                        label = 'Obědy';
                        break;
                      case 'dinner':
                        label = 'Večeře';
                        break;
                      default:
                        label = 'Vše';
                        break;
                    }
                    return (
                      <option key={type} value={type}>
                        {label}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Datum od *</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={values.from}
                  onChange={(value) => {
                    const day = getCorrectDate(value);
                    setFieldError('from', !day ? 'Datum od musí být vyplněno!' : undefined);
                    setFieldValue('from', day);
                  }}
                  locale="cs"
                  dateFormat="dd. MM. yyyy"
                />
                <Form.Control.Feedback type="invalid">{errors.from}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Datum do</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={values.to}
                  onChange={(value) => {
                    const to = getCorrectDate(value);
                    setFieldError('to', !to ? 'Datum do musí být vyplněno!' : undefined);
                    setFieldValue('to', to);
                  }}
                  locale="cs"
                  dateFormat="dd. MM. yyyy"
                />
                <Form.Control.Feedback type="invalid">{errors.to}</Form.Control.Feedback>
              </Form.Group>
              <FormGroup>
                <Form.Label>Cena</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={values.price}
                  placeholder="Zadejte cenu"
                  onChange={handleChange}
                  isInvalid={touched.price && errors.price}
                />
                <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
              </FormGroup>
              <FormGroup>
                <Form.Label>Zvýhodněná cena</Form.Label>
                <Form.Control
                  type="number"
                  name="reducedPrice"
                  value={values.reducedPrice}
                  placeholder="Zadejte zvýhodněnou cenu"
                  onChange={handleChange}
                  isInvalid={touched.reducedPrice && errors.reducedPrice}
                />
                <Form.Control.Feedback type="invalid">{errors.reducedPrice}</Form.Control.Feedback>
              </FormGroup>
              <Form.Group>
                <Button variant="outline-dark" disabled={loadingAdd || loadingEdit} type="submit">
                  {buttonText}
                </Button>
              </Form.Group>
            </Col>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PriceListItemFormMultiple;
